import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'dach-prd-danone',

  ctClientId: 'ufTLRntIPRIpLf0g6R2C9eVL',

  ctClientSecret: 'Skhq-A0soDiSXklaeIqPZ_ptpccdvuIz',

  defaultChannelId: '503a13c6-cfee-4b36-98a4-9b13aaa015d1',

  sentryDSN:
    'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  loqate: {
    apiKey: 'NH44-PZ36-ER76-NP17',
    countries: ['DE', 'AT'],
  },

  adyen: {
    clientKey: 'live_EMGG6IEHXRAXNKFEK7AIJEQ6SYCDEFRU',
    environment: 'live',

    googlePay: {
      environment: 'PRODUCTION',
      gatewayMerchantId: 'DanoneNutricia',
      merchantName: 'Nutricia Milupa GmbH',
      merchantId: 'BCR2DN6TT6SZHQQD',
    },
  },
}
