// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  generateOverrides,
  generateTheme,
  style,
} from '@danone-global/internal/react/themes/nutricia'
import deepMerge from 'deepmerge'

/**
 * The German version of the Nutricia theme has some small changes.
 */

// Update style
const updatedStyle = deepMerge(style, {
  colors: {
    text: {
      primary: '#51338b',
    },
  },
})

// Get default Theme with modified style
const theme = generateTheme(updatedStyle)

// Get overrides

// Define overrides changes
const overrides = {
  Button: {
    brand: {
      '&:hover': {
        backgroundColor: '#8f67a4',
      },
    },
  },
  Typography: {
    h1: {
      fontWeight: theme.typography.fontWeightBold,
    },
    h2: {
      fontWeight: theme.typography.fontWeightBold,
    },
    h3: {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  FieldsCheckbox: {
    label: {
      '& a': {
        textDecoration: 'underline',
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },
  },
  Checkout: {
    securePayment: {
      'p&': {
        color: style.colors.text.primary,
      },
    },
  },

  DiscountCodePrice: {
    unmatchedDiscountText: {
      color: 'red',
      fontWeight: 800,
    },
  },
}

theme.overrides = deepMerge(generateOverrides(updatedStyle), overrides)

export { updatedStyle as style, theme }
